'use client';

import { useState } from 'react';
import Link from 'next/link';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { List, ListItemAvatar, ListItemText, MenuList, Typography, ListItemButton } from '@mui/material';
import { Rotate } from '@qb/frontend/components/Rotate';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { navOnlySlides, slidesData } from '@/app/(index)/_components/UmbrellaIntro/umbrellaIntro.data';
import { UmbrellaButton } from '@/app/(index)/_components/_components/UmbrellaButton';
import { PopperWrapper } from '@/app/_components/Header/_components/PopperWrapper';
export const MenuContainer = () => {
  const isMobile = useIsMobile();
  const [solutionsAnchorEl, setSolutionsAnchorEl] = useState<null | HTMLElement>(null);
  const [resourcesAnchorEl, setResourcesAnchorEl] = useState<null | HTMLElement>(null);
  return <>
      <UmbrellaButton sx={{
      px: {
        xs: 2,
        md: 1.5,
        lg: 2
      },
      height: {
        xs: 35,
        md: 40
      },
      fontWeight: 600
    }} variant="text" onClick={e => setSolutionsAnchorEl(solutionsAnchorEl ? null : e.currentTarget)} data-sentry-element="UmbrellaButton" data-sentry-source-file="MenuContainer.tsx">
        Solutions
        {isMobile && <Rotate isRotated={Boolean(solutionsAnchorEl)}>
            <ExpandMoreIcon color="secondary" sx={{
          ml: 0.5
        }} />
          </Rotate>}
      </UmbrellaButton>
      <PopperWrapper anchorEl={solutionsAnchorEl} setAnchorEl={setSolutionsAnchorEl} placement="bottom" data-sentry-element="PopperWrapper" data-sentry-source-file="MenuContainer.tsx">
        <SolutionsSubMenu onClick={() => {
        setSolutionsAnchorEl(null);
      }} data-sentry-element="SolutionsSubMenu" data-sentry-source-file="MenuContainer.tsx" />
      </PopperWrapper>
      <Link target="_blank" rel="noopener noreferrer" href={(process.env.WEB_HOST as string)} data-sentry-element="Link" data-sentry-source-file="MenuContainer.tsx">
        <UmbrellaButton sx={{
        px: {
          xs: 2,
          md: 1.5,
          lg: 2
        },
        height: {
          xs: 35,
          md: 40
        },
        fontWeight: 600
      }} variant="text" data-sentry-element="UmbrellaButton" data-sentry-source-file="MenuContainer.tsx">
          Marketplace
        </UmbrellaButton>
      </Link>
      <UmbrellaButton sx={{
      px: {
        xs: 2,
        md: 1.5,
        lg: 2
      },
      height: {
        xs: 35,
        md: 40
      },
      fontWeight: 600
    }} variant="text" onClick={e => setResourcesAnchorEl(resourcesAnchorEl ? null : e.currentTarget)} data-sentry-element="UmbrellaButton" data-sentry-source-file="MenuContainer.tsx">
        Resources
        {isMobile && <Rotate isRotated={Boolean(resourcesAnchorEl)}>
            <ExpandMoreIcon color="secondary" sx={{
          ml: 0.5
        }} />
          </Rotate>}
      </UmbrellaButton>
      <PopperWrapper anchorEl={resourcesAnchorEl} setAnchorEl={setResourcesAnchorEl} data-sentry-element="PopperWrapper" data-sentry-source-file="MenuContainer.tsx">
        <ResourcesSubMenu onClick={() => {
        setResourcesAnchorEl(null);
      }} data-sentry-element="ResourcesSubMenu" data-sentry-source-file="MenuContainer.tsx" />
      </PopperWrapper>
    </>;
};
type SolutionsSubMenuProps = {
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
};
const SolutionsSubMenu = ({
  onClick
}: SolutionsSubMenuProps) => {
  const isMobile = useIsMobile();
  return <List sx={{
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      md: '1fr 1fr'
    },
    columnGap: 2,
    rowGap: 0.5,
    pt: [0, 0, 1.75],
    pb: 1.75,
    px: 1.75,
    ml: [2, 2, 0]
  }} data-sentry-element="List" data-sentry-component="SolutionsSubMenu" data-sentry-source-file="MenuContainer.tsx">
      {[...slidesData, ...navOnlySlides].map(({
      title,
      href,
      description,
      Icon,
      order
    }) => <ListItemButton key={title} href={href} disableRipple={isMobile} alignItems="flex-start" onClick={onClick} sx={{
      pt: 0.75,
      pb: 0,
      px: [0, 0, 1.5],
      minHeight: [0, 0, 84],
      borderRadius: '10px',
      order,
      '&:hover': {
        bgcolor: {
          xs: 'transparent',
          md: 'primary.8p'
        }
      }
    }}>
            <ListItemAvatar sx={{
        minWidth: 46
      }}>
              <Icon width={27} height={27} />
            </ListItemAvatar>
            <ListItemText sx={{
        mb: 0
      }} primary={title} secondary={description} slotProps={{
        primary: {
          variant: 'bodyMediumPrimary',
          color: 'text.primary',
          whiteSpace: ['unset', 'unset', 'pre-wrap'],
          mb: 0.75
        },
        secondary: {
          variant: 'bodySmall',
          color: 'text.primary',
          maxWidth: ['unset', 'unset', 262]
        }
      }} />
          </ListItemButton>)}
    </List>;
};
type ResourcesSubMenuProps = {
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
};
const ResourcesSubMenu = ({
  onClick
}: ResourcesSubMenuProps) => {
  return <MenuList sx={{
    px: [1.5, 1.5, 1],
    py: [0, 0, 1.75]
  }} data-sentry-element="MenuList" data-sentry-component="ResourcesSubMenu" data-sentry-source-file="MenuContainer.tsx">
      <ListItemButton href="https://get.quotebeam.com/events" onClick={onClick} data-sentry-element="ListItemButton" data-sentry-source-file="MenuContainer.tsx">
        <Typography variant="bodyMediumPrimary" sx={{
        color: 'text.primary'
      }} data-sentry-element="Typography" data-sentry-source-file="MenuContainer.tsx">
          Events
        </Typography>
      </ListItemButton>
      <ListItemButton href="https://get.quotebeam.com/customer-stories" onClick={onClick} data-sentry-element="ListItemButton" data-sentry-source-file="MenuContainer.tsx">
        <Typography variant="bodyMediumPrimary" sx={{
        color: 'text.primary'
      }} data-sentry-element="Typography" data-sentry-source-file="MenuContainer.tsx">
          Customer Stories
        </Typography>
      </ListItemButton>
      <ListItemButton href={`${process.env.WEB_HOST}/about-us`} onClick={onClick} data-sentry-element="ListItemButton" data-sentry-source-file="MenuContainer.tsx">
        <Typography variant="bodyMediumPrimary" sx={{
        color: 'text.primary',
        textDecoration: 'none'
      }} data-sentry-element="Typography" data-sentry-source-file="MenuContainer.tsx">
          About Us
        </Typography>
      </ListItemButton>
      <ListItemButton href={`${process.env.WEB_HOST}/blog`} onClick={onClick} data-sentry-element="ListItemButton" data-sentry-source-file="MenuContainer.tsx">
        <Typography variant="bodyMediumPrimary" sx={{
        color: 'text.primary'
      }} data-sentry-element="Typography" data-sentry-source-file="MenuContainer.tsx">
          Blog
        </Typography>
      </ListItemButton>
    </MenuList>;
};